import { createElement, useEffect, useRef } from 'react';
import Odometer from 'odometer';
const ReactOdometer = ({ animation, duration, format, theme, value, ...rest }) => {
    const node = useRef(null);
    const odometer = useRef();
    useEffect(() => {
        odometer.current = new Odometer({
            el: node.current,
            auto: false,
            animation,
            duration,
            format,
            theme,
            value,
        });
    }, []);
    useEffect(() => {
        odometer.current?.update(value);
    }, [value]);
    return createElement('div', {
        ...rest,
        ref: node,
    });
};
export default ReactOdometer;
